<template>

    <h1 class="text-center p-5">{{ $t("Dashboard") }}</h1>
    <p class="text-center pb-5">{{ $t("Coming soon") }}</p>
    <p class="text-center pb-5">MANAGMENT</p>

</template>

<script>
import { useMeta } from 'vue-meta'

import 'vue3-carousel/dist/carousel.css';

export default {
    name: 'ManagmentDashboard',
    mounted () {
        useMeta({
            title: this.$t("Dashboard"),
            description: this.$t(""),
        })
    }
}
</script>
